export const injectScript = (
  src: string,
  attrs?: Record<string, string> | null,
  onerror?: () => void,
) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.setAttribute('src', src);

  if (attrs) {
    Object.entries(attrs).forEach(([key, value]) =>
      script.setAttribute(key, value),
    );
  }
  if (onerror) {
    script.onerror = onerror;
  }

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(script);
};

// note document.currentScript will be null if called from a callback or event listener
const getCurrentScriptUrlObject = () => {
  const currentScript = document.currentScript as HTMLScriptElement;
  if (currentScript) {
    return new URL(currentScript.src);
  }
  return null;
};

export const getCurrentScriptBaseDomain = () => {
  const url = getCurrentScriptUrlObject();
  if (url) {
    return url.host;
  }
  return '';
};

export const getCurrentScriptBaseUrl = () => {
  const url = getCurrentScriptUrlObject();
  if (url) {
    return `${url.protocol}//${url.host}`;
  }
  return '';
};

export const getCurrentScriptBasePath = () => {
  const url = getCurrentScriptUrlObject();
  if (url) {
    return url.pathname;
  }
  return '';
};
