import {
  getCurrentScriptBaseUrl,
  getCurrentScriptBasePath,
  getCurrentScriptBaseDomain,
  injectScript,
} from './utils';
import { clientLogger } from './api/endpoints';
import { injectStylesheet } from './utils/stylesheet';

type Manifest = Record<string, string>;

const baseUrl = getCurrentScriptBaseUrl();
const basePath = getCurrentScriptBasePath();
const baseDomain = getCurrentScriptBaseDomain();

const logError = (value: string) => {
  // eslint-disable-next-line no-console
  console.error(`Beamery Convert Flow: `, value);

  return fetch(`https://frontier.${baseDomain}/${clientLogger}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      value,
      meta: {
        file: basePath,
      },
    }),
  });
};

const getManifest = (): Promise<Manifest> =>
  fetch(`${baseUrl}/manifest.embedded.json`).then(
    (response) => response.json() as Promise<Manifest>,
  );

const getAssetUrl = (assetName: string, manifest: Manifest) =>
  `${baseUrl}${manifest[assetName]}`;

const injectScriptFromManifest = (assetName: string, manifest: Manifest) => {
  if (manifest[assetName]) {
    injectScript(
      getAssetUrl(assetName, manifest),
      {
        defer: 'true',
      },
      () => logError(`Failed to load ${assetName}`),
    );
  }
};

const injectStylesheetFromManifest = (
  assetName: string,
  manifest: Manifest,
) => {
  if (manifest[assetName]) {
    injectStylesheet(getAssetUrl(assetName, manifest), null, () =>
      logError(`Failed to load ${assetName}`),
    );
  }
};

const injectAssets = (manifest: Manifest) => {
  injectScriptFromManifest('runtime.js', manifest);
  injectScriptFromManifest('vendors.js', manifest);
  injectScriptFromManifest('main.js', manifest);
  injectStylesheetFromManifest('main.css', manifest);
};

getManifest()
  .then((manifest) => {
    injectAssets(manifest);
  })
  .catch((err: Error) => logError(err.message));
