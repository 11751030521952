export const injectStylesheet = (
  href: string,
  attrs?: Record<string, string> | null,
  onerror?: () => void,
) => {
  const link = document.createElement('link');
  link.href = href;
  link.rel = 'stylesheet';

  if (attrs) {
    Object.entries(attrs).forEach(([key, value]) =>
      link.setAttribute(key, value),
    );
  }
  if (onerror) {
    link.onerror = onerror;
  }

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};
